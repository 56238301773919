import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "42",
  height: "42",
  viewBox: "0 0 42 42",
  fill: "white",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "inherit",
      d: "M11.5475 33.0306V8.96973L30.4524 21.0001L11.5475 33.0306ZM14.9847 26.7576L24.0076 21.0001L14.9847 15.2427V26.7576Z"
    }, null, -1)
  ])))
}
export default { render: render }